<template>
  <div id="importResult">
    <div class="result-box">
      <img v-lazy="dataInfo.pic" alt />
      <p class="title">{{dataInfo.title}}</p>
      <p class="tips-main">{{dataInfo.tipsMain}}</p>
      <p class="tips">{{dataInfo.tips}}</p>
      <div class="btn-box">
        <div class="btn" @click="$router.replace({path: '/importProduct'})">跳过</div>
        <div
          class="btn active"
          @click="$router.replace({path: '/setImportList',query:{res: $route.query.res}})"
        >继续</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      dataInfo: {
        pic: require("@images/success.png"),
        title: "导入成功",
        tipsMain: "是否前往编辑所导入商品的价格和活动信息",
        tips: "在此环节编辑所提交的内容无需审核即可生效",
      },
    };
  },
  created() {
    // console.log(this.$route.query);
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" >
@import "../common/css/theme.scss";
#importResult {
  min-height: 100vh;
  background: rgba(245, 247, 250, 1);
  .result-box {
    height: 227px;
    padding-top: 6.4 * 3.75px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 1);
    img {
      height: 16 * 3.75px;
      width: 16 * 3.75px;
      margin: 0 auto 1.6 * 3.75px;
      display: block;
    }

    .title {
      margin: 6px auto;
      font-size: 3.73 * 3.75px;
      color: rgba(96, 98, 102, 1);
      text-align: center;
      font-weight: bold;
    }
    .tips-main {
      text-align: center;
      margin-bottom: 1px;
      color: rgba(144, 147, 153, 1);
      font-size: 3.2 * 3.75px;
    }

    .tips {
      text-align: center;
      color: rgba(144, 147, 153, 1);
      font-size: 3.2 * 3.75px;
      margin-bottom: 16px;
    }
    .btn-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn {
      width: 29.33 * 3.75px;
      text-align: center;
      height: 8 * 3.75px;
      border: 1px solid $theme-color;
      opacity: 1;
      border-radius: 1 * 3.75px;
      font-size: 3.73 * 3.75px;
      line-height: 8 * 3.75px;
      color: $theme-color;
      box-sizing: border-box;
      &:first-child {
        margin-right: 1.6 * 3.75px;
      }
      &.active {
        background: $theme-color;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>
